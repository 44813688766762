<template>
  <div>
    <div class="m-0 pl-4 pt-3 pb-1">
      <h1 class="ml-2">Dashboard</h1>
    </div>

    <base-header class="pb-6">

      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-4 col-md-4">
          <stats-card title="TOTAL PATIENTS"
                      type="gradient-red"
                      :sub-title="this.total_patients"
                      icon="/img/gray_man.svg">

          </stats-card>
        </div>
        <div class="col-xl-4 col-md-4">
          <stats-card title="New Patients"
                      type="gradient-orange"
                      :sub-title="new_patients"
                      caption="(This Year)"
                      icon="/img/tea_green_man.svg">

          </stats-card>
        </div>
        <div class="col-xl-4 col-md-4">
          <stats-card title="TOTAL WEIGHT LOSS"
                      type="gradient-green"
                      :sub-title="total_weight_loss"
                      caption="(lbs)"
                      icon="/img/light_blue_man.svg">

          </stats-card>

        </div>

      </div>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12">
          <card type="default" header-classes="bg-transparent" v-loading="loading.graph">
            <div slot="header" class="row align-items-center dashboard-header">
              <h3 id="total" class="text-uppercase font-weight-700">
                Total {{this.bigLineChart.patientCount[bigLineChart.activeIndex]}}</h3>
              <div class="col">
                <h3 class=" text-uppercase ls-1 font-weight-700">NEW PATIENTS</h3>
              </div>
              <div class="col">
                <ul class="nav nav-pills justify-content-end">
                  <li class="nav-item mr-2 mr-md-0">
                    <a class="nav-link py-2 px-3 font-weight-bold"
                       href="#"
                       :class="{active: bigLineChart.activeIndex === 0}"
                       @click.prevent="patientGraph(0)">
                      <span class="d-none d-md-block">Year</span>
                      <span class="d-md-none">Y</span>
                    </a>
                  </li>
                  <li class="nav-item mr-2 mr-md-0">
                    <a class="nav-link py-2 px-3 font-weight-bold"
                       href="#"
                       :class="{active: bigLineChart.activeIndex === 1}"
                       @click.prevent="patientGraph(1)">
                      <span class="d-none d-md-block">Month</span>
                      <span class="d-md-none">M</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link py-2 px-3 font-weight-bold"
                       href="#"
                       :class="{active: bigLineChart.activeIndex === 2}"
                       @click.prevent="patientGraph(2)">
                      <span class="d-none d-md-block">Week</span>
                      <span class="d-md-none">W</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <line-chart
              :height="350"
              ref="bigChart"
              :chart-data="bigLineChart.chartData"
              :extra-options="bigLineChart.extraOptions"
            >
            </line-chart>


          </card>
        </div>
      </div>
      <!-- End charts-->
    </div>

  </div>
</template>
<script>
import moment from 'moment';
// Charts
import LineChart from '@/components/Charts/LineChart';

import * as chartConfigs from '@/components/Charts/config';

export default {
  components: {
    LineChart,
  },
  data() {
    return {
      yearMax: 0,
      loading: {
        graph: false,
      },
      total_patients: '',
      new_patients: '',
      total_weight_loss: null,
      bigLineChart: {
        allData: [
          [0],
          [0],
          [0],
        ],
        allLabels: [
          [''],
          [''],
          ['']
        ],
        patientCount:[0, 0, 0],
        activeIndex: 0,
        chartData: {
          datasets: [
            {
              label: 'Patients',
              data: [0, 20, 10, 30, 15, 40, 20, 60],
              fill: true,
              borderColor: "#2F597A",
              pointBackgroundColor: "#832C44",
              pointBorderColor: "#FFFFFF",
              pointBorderWidth: 3,
              pointStrokeColor: "#fff",
              pointHitRadius: 1,
              pointRadius: 5,
            }
          ],
          labels: [''],
        },
        extraOptions: chartConfigs.equalDistribution,
        color: '#F5AF19',
      }
    };
  },
  created() {
    // this.getDashboardData();
    // this.getGraph();
  },
  methods: {
    patientGraph(index) {

      this.bigLineChart.chartData = {
        datasets: [
          {
            label: 'Patients',
            data: this.bigLineChart.allData[index],
            fill: true,
            borderColor: "#2F597A",
            pointBackgroundColor: "#832C44",
            pointBorderColor: "#FFFFFF",
            pointBorderWidth: 3,
            pointStrokeColor: "#fff",
            pointHitRadius: 1,
            pointRadius: 5,
          }
        ],
        labels: this.bigLineChart.allLabels[index],
      };
      this.bigLineChart.activeIndex = index;
      if (index === 0) {
        this.assignYaxisStepSize()
        this.bigLineChart.extraOptions = chartConfigs.yearGraph;
      } else if (index === 1) {
        this.bigLineChart.extraOptions = chartConfigs.monthGraph;
      } else {
        this.bigLineChart.extraOptions = chartConfigs.weekGraph;
      }
    },

    assignYaxisStepSize() {
      let size = this.yearMax / 6
      size = Math.ceil(size / 10) * 10
      chartConfigs.yearGraph.scales.yAxes[0].ticks.stepSize = size;
    },

    getDashboardData() {
      axios.get(this.$store.getters.getBaseUrl + '/api/dashboard-data')
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.total_patients = response.data.data.total_patients.toString();
          this.new_patients = response.data.data.current_year_patients.toString();
          this.total_weight_loss = response.data.data.total_weight_loss ? response.data.data.total_weight_loss.toFixed(0):0;
          this.total_weight_loss = Number(this.total_weight_loss).toLocaleString();
          this.total_weight_loss = this.total_weight_loss.toString();
      })
        .catch(error => {
        console.error(error)
      });
    },

    getGraph() {
      let vm = this;
      vm.loading.graph = true;
      axios.get(this.$store.getters.getBaseUrl + '/api/dashboard-graph').then((response) => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        let data = response.data.data;
        this.prepareYearGraph(data);
        this.prepareMonthGraph(data);
        this.prepareWeekGraph(data);
      }).catch(error => {
        console.error(error.response)
      }).finally(() => {
        vm.loading.graph = false;
        this.patientGraph(this.activeIndex);
      });
    },

    prepareYearGraph(graphData) {
      let labels = [], count = [];
      for (let data of graphData.year) {
        let month = moment(data).format('YYYY-MM');
        let patients = graphData.patients.filter(patient => moment(patient.created_at).format('YYYY-MM') === month);
        count.push(patients.length);
        this.yearMax = (patients.length > this.yearMax) ? patients.length : this.yearMax
        this.bigLineChart.patientCount[0] += patients.length
        labels.push(moment(month).format('MMM'));
      }
      // console.log(this.bigLineChart.patientCount[0])
      this.bigLineChart.allData[0] = count
      this.bigLineChart.allLabels[0] = labels

    },

    prepareMonthGraph(graphData) {
      let labels = [], count = [];
      for (let month of graphData.month) {
        let date = moment(month).format('YYYY-MM-DD');
        let patients = graphData.patients.filter(patient => moment(patient.created_at).format('YYYY-MM-DD') === date);
        this.bigLineChart.patientCount[1] += patients.length
        count.push(patients.length);
        labels.push(month);
      }
      // console.log('count =',count ,'labels = ', labels);
      this.bigLineChart.allData[1] = count;
      this.bigLineChart.allLabels[1] = labels;
    },

    prepareWeekGraph(graphData) {
      let labels = [], count = [];
      for (let week of graphData.week) {
        let date = moment(week).format('YYYY-MM-DD');
        let patients = graphData.patients.filter(patient => moment(patient.created_at).format('YYYY-MM-DD') === date);
        this.bigLineChart.patientCount[2] += patients.length
        count.push(patients.length);
        labels.push(week);
      }
      this.bigLineChart.allData[2] = count;
      this.bigLineChart.allLabels[2] = labels;
    }
  },
  mounted() {
    this.activeIndex = 0 // Year Index
    this.getDashboardData();
    this.getGraph();
  }
};
</script>

<style scoped>
.nav-link.active {
  background-color: #2F597A !important;
  color: white;
}

.dashboard-header {
  position: relative;
}

.dashboard-header #total {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
